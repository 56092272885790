.newsSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  margin: 100px auto 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.newsTitle {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.newsTitle h2 {
  margin: 0;
}
.heading1 {
  margin: 0;
  composes: heading1 from global;
  color: var(--color-primary);
}
.arrow {
  height: 50px;
  line-height: 50px;
  composes: paragraph2 from global;
  cursor: pointer;
  transition: all 200ms ease-out;
}
.arrow:hover {
  color: var(--color-accent);
  font-weight: 700;
  scale: 1.1;
}
.newsContents {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .newsSection {
    padding: 0.5rem 50px;
  }
}
@media (max-width: 767px) {
  .newsSection {
    margin: 50px auto;
    padding: 0 20px;
  }
}
