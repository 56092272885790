.solutionPlanSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  color: var(--color-primary);
}
.titleSection {
  text-align: center;
}
.titleSection h1 {
  font-weight: 700;
  font-size: 60px;
  margin: 10px;
}
.titleSection p {
  font-size: var(--size-font-paragraph1);
}
.planSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.planSection__box {
  box-sizing: border-box;
  width: 366px;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--color-primary);
}
.planSection__box h3 {
  size: var(--size-font-heading3);
}
.planSection__box p {
  size: var(--size-font-paragraph1);
  text-align: center;
  margin-top: 0;
}
.price {
  font-size: var(--size-font-heading1);
  font-weight: 700;
}
.color {
  color: var(--color-accent);
}
.planSection__box__info {
  width: 100%;
}
.planSection__box__info ul {
  padding: 0;
}
.planSection__box__info svg {
  font-size: 30px;
  color: var(--color-accent);
}
.planSection__box__info ul li {
  display: flex;
  align-items: center;
}
.planSection__box__info ul:nth-child(1) li {
  margin: 0;
}
.planSection__box__info ul:nth-child(2) {
  color: var(--color-text);
  padding: 10px 30px;
}
.buttonSection {
  width: 100%;
  margin-top: 20px;
}
.planDetailSection {
  width: 100%;
}
.table tr:first-of-type th {
  background-color: var(--color-darkGray);
  color: white;
}
.table th {
  border: 1px solid #ddd;
  background-color: var(--color-lightGray);
}
.table td,
.table tr,
.table tr:hover {
  background-color: unset;
  text-align: unset;
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-collapse: collapse;
  color: var(--color-primary);
  font-weight: 400;
}
.table tr td:nth-child(2) {
  text-align: center;
}
.table li {
  list-style-type: disc;
  font-weight: 400;
}
.noticeSection {
  width: 100%;
}
.noticeSection__title {
  display: flex;
  align-items: center;
}
.noticeSection__title svg {
  font-size: 30px;
}
.noticeSection p {
  padding: 10px 20px;
  color: var(--color-text);
}
.noticeSection__contents {
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  padding: 30px;
}
.noticeSection__contents li {
  list-style-type: disc;
}
.bannerSection {
  box-sizing: border-box;
  width: 100vw;
  height: 500px;
  background-image: url("/public/images/solutionBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 400px;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
  color: white;
}
