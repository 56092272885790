a {
  text-decoration: none;
  color: black;
  margin: auto 0;
}
.navBox {
  position: relative;
  width: 100%;
  background-color: white;
}
.bannerSection {
  width: 100%;
  height: 80px;
  /* background-color: var(--color-accent); */
  background-color: #191919;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--size-max-width);
  height: 100px;
  padding: 0 30px;
  background-color: white;
  color: black;
  gap: 1.5rem;
}
.navLogo {
  display: flex;
  gap: 1rem;
}
.navTitle {
  font-size: var(--size-font-heading3);
  color: var(--color-primary);
}
.nav {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: var(--size-font-paragraph2);
  font-weight: 500;
}
.navLink {
  color: white;
  position: relative;
  font-weight: 300;
}

.activeNavLink {
  font-weight: bold;
  font-size: var(--size-font-paragraph1);
}

.activeNavLink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: white;
}
.dropdownMenu {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(46, 158, 102, 1) 0%,
    rgba(37, 92, 170, 1) 100%
  );
  margin: 0;
  padding: 0;
  gap: 1rem;
}
.menuBox {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  top: -5px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}
.solutionName {
  box-sizing: border-box;
  padding: 20px;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
}
nav {
  height: 100px;
  line-height: 100px;
}
.bar {
  background: linear-gradient(
    90deg,
    rgba(46, 158, 102, 1) 0%,
    rgba(37, 92, 170, 1) 100%
  );
  width: 100%;
  height: 5px;
}
@media (max-width: 767px) {
  .bannerSection {
    flex-direction: column;
    gap: 0;
    height: 100%;
    padding: 20px 0;
  }
  .nav {
    gap: 1rem;
    white-space: nowrap;
  }
  .bannerSection > p {
    margin: 5px;
  }
}
