:root {
  /* App Colors */
  --color-primary: var(--color-black);
  --color-primary-variant: var(--color-white);
  --color-accent: var(--color-green600);
  --color-accent-variant: var(--color-green300);
  --color-text: var(--color-darkGray);

  /* Background */
  --color-home-bg: var(--color-white);
  --home-bg-svg: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none' %3E%3Cpath d='M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z' class='shape-fill' fill='%23FFFFFF' fill-opacity='1'%3E%3C/path%3E%3C/svg%3E");

  /* Colors */
  --color-green300: #83d2a6;
  --color-green500: #2e9e66;
  --color-green600: #1e7d4f;
  --color-blue300: #91b8e8;
  --color-blue500: #3075c7;
  --color-blue600: #215ba9;
  --color-lightGray: #e9e9e9;
  --color-gray: #828282;
  --color-darkGray: #4a4a4a;
  --color-black: #191919;
  --color-white: #fbfbfb;

  /* Size */
  --size-max-width: 1200px;

  --size-font-heading1: clamp(28px, 5vw, 40px); /* 최소 24px, 최대 40px */
  --size-font-heading2: clamp(20px, 4vw, 28px); /* 최소 18px, 최대 28px */
  --size-font-heading3: clamp(18px, 3.5vw, 24px); /* 최소 16px, 최대 24px */
  --size-font-paragraph1: clamp(16px, 3vw, 20px); /* 최소 14px, 최대 20px */
  --size-font-paragraph2: clamp(14px, 2.5vw, 16px); /* 최소 12px, 최대 16px */
  --size-font-paragraph3: clamp(12px, 2vw, 14px); /* 최소 10px, 최대 14px */
}

/* commonStyle */
.heading1 {
  font-size: var(--size-font-heading1);
  line-height: 150%;
  font-weight: 700;
  letter-spacing: -1.6px;
}

.heading2 {
  font-size: var(--size-font-heading2);
  line-height: 150%;
  font-weight: 700;
  letter-spacing: -1px;
}

.heading3 {
  font-size: var(--size-font-heading3);
  line-height: 150%;
  font-weight: 700;
  letter-spacing: -1px;
}

.paragraph1 {
  font-size: var(--size-font-paragraph1);
  line-height: 150%;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.paragraph2 {
  font-size: var(--size-font-paragraph2);
  line-height: 180%;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.paragraph3 {
  font-size: var(--size-font-paragraph3);
  line-height: 150%;
  font-weight: 500;
}

* {
  scroll-behavior: smooth;
  list-style: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 360px;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-home-bg);
  color: var(--color-text);
  font-size: var(--size-font-paragraph2);
  line-height: 180%;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked {
  accent-color: var(--color-accent);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
