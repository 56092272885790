.solutionSection {
  box-sizing: border-box;
  width: 100%;
  /* max-width: var(--size-max-width); */
  margin: 100px auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  position: relative;
}
.fastSection {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 10;
}
.fastSection_button {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 1px solid var(--color-lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  background-color: white;
}
.fastSection_button > img {
  width: 70px;
}
.bannerSection {
  width: 100%;
  height: 500px;
  background-image: url("/public/images/solutionBG1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .bannerSection {
    display: none;
  }
  .fastSection {
    bottom: 18px;
    right: 18px;
    gap: 0.5rem;
  }
  .fastSection_button {
    width: 70px;
    height: 70px;
    border-radius: 45px;
    font-size: 50px;
  }
  .fastSection_button > img {
    width: 40px;
  }
}
