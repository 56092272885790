.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
table {
  box-sizing: border-box;
  width: 100%;
  margin: 30px 0;
  border-collapse: collapse;
}

table td,
table th {
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  /* white-space: nowrap; */
}
table td:nth-child(3) {
  text-align: left;
  padding-left: 30px;
  font-weight: 500;
  white-space: unset;
}
table tr {
  background-color: transparent;
}
/* table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

table tr:hover {
  background-color: white;
}

table th {
  background-color: #fff;
  font-weight: 500;
  /* color: white; */
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination {
  text-align: center;
  font-size: var(--size-font-paragraph3);
}
.pagination button {
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
}
.selectBox {
  /* position: absolute;
  right: 0;
  bottom: 20px; */
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  color: var(--color-text);
}

@media (max-width: 767px) {
  .titleSection {
    flex-direction: column;
    align-items: unset;
  }
  table th:nth-child(1),
  table td:nth-child(1) {
    padding: 0;
  }
  table td:nth-child(3) {
    text-align: center;
    /* padding-left: unset; */
    font-weight: unset;
    white-space: unset;
  }
  table td:nth-child(2) {
    font-weight: 500;
  }
}
