.technicalAccountSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  min-height: 50vh;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}
.technicalAccountTitle {
  margin-bottom: 50px;
}
.heading1 {
  margin: 0;
  font-size: var(--size-font-heading1);
  color: var(--color-primary);
}
.checkBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-weight: 500px;
  gap: 1rem;
  padding-bottom: 5px;
  composes: paragraph3 from global;
}

.checkBoxContents {
  display: flex;
  align-items: center;
  gap: 2px;
}
@media (max-width: 767px) {
  .technicalAccountSection {
    padding: 0 20px;
  }
}
