.solutionSection {
  box-sizing: border-box;
  max-width: var(--size-max-width);
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.solutionTitle {
  display: flex;
  margin-bottom: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.heading1 {
  composes: heading1 from global;
  margin: 0;
  color: var(--color-primary);
}
.arrow {
  height: 50px;
  line-height: 50px;
  composes: paragraph2 from global;
  cursor: pointer;
  transition: all 200ms ease-out;
}
.arrow:hover {
  color: var(--color-accent);
  font-weight: 700;
  scale: 1.1;
}
.solutionContents {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* 모든 아이템의 높이를 동일하게 맞춤 */
  gap: 1rem;
}

.solutionBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 560px;
  gap: 1rem;
}
.solution {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  /* flex-direction: column; */
  align-items: center;
  padding: 30px;
  /* min-width: 450px; */
  height: 352px;
  min-height: 250px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
}
.solution_images {
  width: 200px;
}
.button_download {
  width: 100%;
}
.solution.hovered {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.solution img {
  transition: all 300ms ease-out;
}
.solution img.hovered {
  transform: scale(0);
}

.video {
  position: absolute;
  border: 1px solid var(--color-lightGray);
  top: -100px;
  left: 550px;
  width: 1000px;
  height: 600px;
  object-fit: cover;
  /* display: none; */
  z-index: 10;
}
.video1 {
  position: absolute;
  border: 1px solid var(--color-lightGray);
  top: -100px;
  right: 550px;
  width: 1000px;
  height: 600px;
  object-fit: cover;
  /* display: none; */
  z-index: 10;
}
.hr {
  margin: 20px 20px;
  width: 1px;
  background: var(--color-lightGray);
  height: 100%;
  border: 0;
}
.solution.hovered .hr {
  opacity: 0;
}
.cardBox {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-primary);
  gap: 15px;
}
.cardBox__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cardTitle {
  composes: heading1 from global;
  margin: 5px 0;
}
.cardSubtitle {
  composes: paragraph3 from global;
  color: var(--color-text);
  margin: 5px 0;
}
.downloadIcon {
  display: flex;
  align-items: center;
}
.downloadIcon svg {
  font-size: 40px;
  transition: all 200ms ease-out;
}
.downloadIcon svg:hover {
  color: green;
  font-weight: 700;
  scale: 1.2;
}
.mainImage__text__title__video {
  color: var(--color-accent);
  font-weight: 700;
  cursor: pointer;
  padding: 20px;
  position: absolute;
  top: 30px;
  right: 30px;
}
.mainImage__text__title__video:hover {
  color: var(--color-accent-variant);
}
@media (min-width: 768px) and (max-width: 1023px) {
  .solutionSection {
    padding: 0.5rem 50px;
  }
  .solution {
    width: 100%;
    gap: 1rem;
    max-width: 600px;
  }
  .video {
    left: 0;
    top: 100px;
    width: 100%;
    height: unset;
  }
  .video1 {
    right: 0;
    top: 100px;
    width: 100%;
    height: unset;
  }
}
@media (max-width: 767px) {
  .solutionSection {
    margin: 50px 0;
    padding: 25px 20px;
  }
  .solution {
    height: unset;
  }
  .solution_images {
    width: 30%;
  }
  .mainImage__text__title__video {
    color: var(--color-accent);
    font-weight: 700;
    cursor: pointer;
    padding: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .video {
    left: 0;
    top: 70px;
    width: 100%;
    height: unset;
  }
  .video1 {
    right: 0;
    top: 70px;
    width: 100%;
    height: unset;
  }
}
