.productDetailSection {
  margin: 50px 0 100px;
  max-width: var(--size-max-width);
  width: 100%;
  color: var(--color-primary);
  position: relative;
}
.mainImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;
}
.mainImage__text {
  width: 55%;

  composes: paragraph2 from global;
  line-height: 1.8rem;
}
.mainImage__text__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainImage__text__title__video {
  color: var(--color-accent);
  font-weight: 700;
  cursor: pointer;
  padding: 20px;
}
.mainImage__text__title__video:hover {
  color: var(--color-accent-variant);
}
.video {
  width: 100%;
  border: 1px solid var(--color-lightGray);
  margin-bottom: 200px;
}

.h1 {
  composes: heading1 from global;
  margin-bottom: 50px;
}
.h1 svg {
  font-size: 30px;
  color: var(--color-accent);
}
.imageDescription {
  display: flex;
  flex-direction: column;
  gap: 15rem;
  margin: 100px 0 200px;
}
.imageDescription__row {
  display: flex;
  gap: 4rem;
}
.imageDescription__image {
  width: 60%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.imageDescription__image__video {
  width: 100%;
}
.imageDescription__image img {
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
}

.imageDescription__text {
  box-sizing: border-box;
  width: 40%;
  height: 500px;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0;
  line-height: 1.8rem;
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateY(100px);
  composes: paragraph2 from global;
}
.imageDescription__text > h2 {
  position: relative;
}
.imageDescription__text > p > ul > li {
  list-style: disc;
  text-align: left;
}
.show {
  /* background-color: black; */
  opacity: 1;
  transform: translateY(0px);
}
.imageDescription__text:nth-child(odd) {
  align-items: end;
  text-align: end;
}
.advantages__contents {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.advantages__contents__box {
  width: 31%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  overflow: hidden;
}
.advantages__contents__box__image {
  background-color: white;
}
.advantages__contents__box__image img {
  width: 100%;
}
.advantages__contents__box__text {
  box-sizing: border-box;
  background-color: white;
  height: 100%;
  padding: 30px 25px;
  composes: paragraph2 from global;
}

.awards__contents {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.h2 {
  composes: heading2 from global;
  margin: 0 0 30px;
}
.awards__contents p {
  font-size: var(--size-font-paragraph2);
  margin: 0;
}
.awards__contents__box__text_color {
  color: var(--color-text);
}
.awards__contents__box {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  display: flex;
  overflow: hidden;
}
.awards__contents__box__image {
  text-align: center;
  width: 300px;
  height: 260px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  object-fit: cover;
}
.awards__contents__box__image img {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  background-color: white;
}
.awards__contents__box__text {
  box-sizing: border-box;
  width: 70%;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  composes: paragraph1 from global;
}
.installGuides {
  display: flex;
  justify-content: space-between;
}
.installGuides__contents {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  padding: 65px 50px;
  border-radius: 10px;
  background-color: white;
  color: var(--color-text);
  composes: paragraph2 from global;
}
.installGuides__contents p {
  padding: 0 0 50px 15px;
}
.systemRequirement__table {
  composes: paragraph3 from global;
}
.systemRequirement__table th,
.systemRequirement__table td {
  text-align: left;
}
.systemRequirement__table th {
  color: var(--color-text);
}
.faq {
  /* margin-bottom: 500px; */
}
.faq__contents {
  line-height: 1.5rem;
}
.faq__contents__text {
  display: flex;
  flex-direction: column;
}
.faq__contents__text_titleSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0;
  cursor: pointer;
  composes: paragraph2 from global;
}
.faq__contents__text_title {
  font-weight: 700;
  padding: 0;
}
.buttonSection {
  margin: 50px 0;
  display: flex;
  width: 100%;
  gap: 10px;
}
.buttonSection > a {
  width: 200px;
}
.button_brochure {
  width: 200px;
}
.buttonSection__comingsoon {
  position: relative;
  width: 260px;
}
.buttonSection1 {
  margin: 50px 0;
  display: flex;
  justify-content: end;
  gap: 10px;
}
.hrDotted {
  margin: 150px 50px;
  border: 0px;
  border-top: 5px dotted var(--color-lightGray);
}
.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 25px auto;
  width: 100%;
}
.faq__contents {
  color: var(--color-text);
}

/* .bannerSection {
  box-sizing: border-box;
  width: 100vw;
  position: absolute;
  bottom: 0;
  height: 500px;
  transform: translateX(-25vw);
  background-image: url("/public/images/solutionBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
  color: white;
} */
.bannerSection__contents {
  width: 100%;
  padding: 0 50px;
  gap: 2rem;
  max-width: var(--size-max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
  color: white;
  position: absolute;
  bottom: -500px;
}

.systemRequirement__table tr:hover {
  background-color: unset;
}
.backgroundButton {
  background-color: red;
}

@media (max-width: 767px) {
  .productDetailSection {
    margin: 30px 0;
    padding: 0 20px;
  }
  .mainImage {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 0;
  }
  .mainImage_image {
    width: 200px;
    margin-right: auto;
    margin-left: 10px;
  }
  .mainImage__text {
    width: 95%;
    line-height: 1.4rem;
  }
  .mainImage__text__title {
    display: none;
  }
  .video {
    margin-bottom: 100px;
  }
  .h1 {
    margin-bottom: 20px;
  }
  .heading1 {
    font-size: 40px;
    line-height: 150%;
    font-weight: 700;
    letter-spacing: -1.6px;
    color: var(--color-darkGray);
    margin-bottom: 40px;
  }
  .imageDescription {
    gap: 8rem;
    margin: 0;
  }
  .imageDescription__row {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
  .imageDescription__image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    order: -1;
  }
  .imageDescription__image__video {
    width: 100%;
  }
  .imageDescription__image img {
    position: unset;
  }

  .imageDescription__text {
    width: 100%;
    height: unset;
    padding: 0;
    line-height: 1.4rem;
    opacity: 0;
    transition: all 1000ms ease;
    transform: translateY(100px);
    order: 1;
  }
  .show {
    /* background-color: black; */
    opacity: 1;
    transform: translateY(0px);
  }
  .imageDescription__text:nth-child(odd) {
    align-items: unset;
    text-align: unset;
  }
  .advantages__contents {
    flex-direction: column;
    gap: 5rem;
  }
  .advantages__contents__box {
    width: 100%;
    min-height: unset;
  }
  .advantages__contents__box__text {
    padding: 20px;
    composes: paragraph1 from global;
    line-height: 1.4rem;
  }

  .awards__contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5rem;
  }
  .awards__contents p {
    font-size: var(--size-font-paragraph2);
    margin: 0;
  }
  .awards__contents__box__text_color {
    color: var(--color-text);
  }
  .awards__contents__box {
    border-radius: unset;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .awards__contents__box__image {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 460px;
  }
  .awards__contents__box__image img {
    display: block;
    width: 100%;
    height: 100%;
  }

  /* 
  .awards__contents__box__image {
    text-align: center;
    width: 300px;
    height: 260px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
    object-fit: cover;
  }
  .awards__contents__box__image img {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    background-color: white;
  } */

  .awards__contents__box__text {
    box-sizing: border-box;
    width: 100%;
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    composes: paragraph1 from global;
  }
  .installGuides {
    display: flex;
    justify-content: space-between;
  }
  .installGuides__contents {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    padding: 65px 50px;
    border-radius: 10px;
    background-color: white;
    color: var(--color-text);
    composes: paragraph2 from global;
  }
  .installGuides__contents p {
    padding: 0 0 50px 15px;
  }
  .systemRequirement__table th,
  .systemRequirement__table td {
    text-align: left;
  }
  .systemRequirement__table th {
    color: var(--color-text);
  }
  .faq {
    position: relative;
  }

  .faq__contents {
    line-height: 1.5rem;
  }
  .buttonSection {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  }
  .buttonSection > a {
    width: 100%;
  }
  .buttonSection__comingsoon {
    position: relative;
    width: 100%;
  }
  .buttonSection1 {
    margin: 20px 0;
    display: flex;
    justify-content: start;
    gap: 10px;
    position: absolute; /* 버튼을 부모인 .faq 안에서 절대적인 위치로 배치 */
    top: 0;
    right: 0;
    transform: translateY(-35%);
  }
  .hrDotted {
    margin: 150px 50px;
    border: 0px;
    border-top: 5px dotted var(--color-lightGray);
  }
  .hr {
    background: var(--color-lightGray);
    height: 1px;
    border: 0;
    margin: 25px auto;
    width: 100%;
  }
  .faq__contents {
    color: var(--color-text);
  }
  .bannerSection__contents {
    display: none;
  }

  .systemRequirement__table tr:hover {
    background-color: unset;
  }
  .backgroundButton {
    background-color: red;
  }
}
