.termsofuseSection {
  margin: 100px auto;
  max-width: var(--size-max-width);
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 100px;
  line-height: 1.6rem;
}
.title {
  margin-bottom: 100px;
  text-align: center;
}
.ul {
  margin: 2rem 0;
}
.li {
  margin: 0 0 0 1rem;
  display: block;
}
