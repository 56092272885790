.paymentSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.paymentTitle {
  margin-bottom: 50px;
}
.heading1 {
  margin: 0;
  font-size: var(--size-font-heading1);
  color: var(--color-primary);
}
.heading2 {
  margin: 0;
  font-size: var(--size-font-heading2);
  color: var(--color-primary);
}
.heading3 {
  margin: 0 0 20px;
  font-size: var(--size-font-heading3);
  color: var(--color-primary);
}
.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 20px auto;
  width: 100%;
}
.paymentSection__orderItem {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
}
.paymentSection__orderItem__title,
.paymentSection__contractorInfo__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentSection__orderItem__contents {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.paymentSection__orderItem__solutionImage {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-lightGray);
  align-content: center;
}
.paymentSection__contractorInfo {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
}
.paymentSection__payment {
  display: flex;
  justify-content: space-between;

  gap: 2rem;
}
.paymentSection__paymentMethods {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
}
.paymentSection__paymentAmount {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.paymentSection__orderItem__contents table {
  width: 50%;
}
.table td,
.table th,
.table tr,
.table tr:hover {
  background-color: unset;
  text-align: unset;
  border: unset;
  box-sizing: border-box;
  padding: 10px;
}
.table td:nth-child(even) {
  color: var(--color-primary);
}
.table td input {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph3);
}
.table tr:hover {
  background-color: none;
}

.buttonSection {
  margin: 30px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.touchBtn {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
}
.creditNoteSection {
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  padding: 10px;
}
.noticeSection__title {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  margin-top: 30px;
}
.noticeSection__title svg {
  font-size: 30px;
}

.price {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
  font-weight: 700;
}
.price p {
  margin-bottom: 5px;
}
.color {
  color: var(--color-accent);
}
.checkBoxContents {
  padding: 50px 0 0;
  color: var(--color-primary);
  font-weight: 700;
}
.checkboxAlign {
  display: flex;
  align-items: center;
  gap: 5px;
}
.checkBoxContentsTab {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  font-size: var(--size-font-paragraph3);
  gap: 5px;
  font-weight: 500;
}
.underline {
  text-decoration: underline;
}
.button {
  margin-top: 500px;
}
.payment {
  border-radius: 5px;
  background-color: var(--color-lightGray);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
.payment_align {
  display: flex;
  align-items: center;
  gap: 5px;
}
.withoutPassbookOnSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-primary);
}
.withoutPassbookOnSection__input {
  display: flex;
  align-items: baseline;
}
.withoutPassbookOnSection__input label {
  width: 15%;
}
.withoutPassbookOnSection__input input,
.withoutPassbookOnSection__input select {
  box-sizing: border-box;
  width: 85%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
}
.withoutPassbookOnSection__certificate {
  display: flex;
  margin-left: 15%;
  gap: 10px;
}
.withoutPassbookOnSection__certificate__input {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.modalSection {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20px;
  background-color: var(--color-lightGray);
  width: 800px;
  display: flex;
  padding: 100px;
  flex-direction: column;
  justify-content: center;
  color: var(--color-primary);
}
.modalSection svg {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
}
.modalSection ul {
  padding: 0;
}
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.title {
  font-size: var(--size-font-heading2);
  font-weight: 700;
  color: black;
}
