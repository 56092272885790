.orderDetailSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.orderDetailSection__2 {
  display: flex;
  gap: 2rem;
}
.orderDetailTitle {
  display: flex;
  justify-content: space-between;
}
.heading1 {
  margin: 0;
  font-size: var(--size-font-heading1);
  color: var(--color-primary);
}
.heading3 {
  margin: 0 0 20px;
  font-size: var(--size-font-heading3);
  color: var(--color-primary);
}
.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 20px auto;
  width: 100%;
}
.orderDetailSection__orderItem {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 65%;
  padding: 60px 80px;
}
.orderDetailSection__orderItem__contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orderDetailSection__orderItem__solutionImage {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-lightGray);
  align-content: center;
}
.orderDetailSection__contractorInfo {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
}
.orderDetailSection__orderDetail {
  display: flex;
  justify-content: space-between;

  gap: 2rem;
}
.orderDetailSection__orderDetailMethods {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 60px 80px;
}
.orderDetailSection__orderDetailAmount {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  background-color: white;
  width: 35%;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orderDetailSection__orderItem__contents .table {
  width: 70%;
}
.table td,
.table th,
.table tr,
.table tr:hover {
  background-color: unset;
  text-align: unset;
  border: unset;
  box-sizing: border-box;
  padding: 10px;
}
.table td:nth-child(even) {
  color: var(--color-primary);
}

.buttonSection {
  margin: 30px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.price {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
  font-weight: 700;
}
.price p {
  margin-bottom: 5px;
}
.color {
  color: var(--color-accent);
}
