@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.findId {
  box-sizing: border-box;
  width: 700px;
  margin: 150px auto 250px;
  padding: 100px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  animation: fadeIn 0.5s ease-out forwards;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.findIdTitle {
  font-size: var(--size-font-heading2);
  font-weight: 700;
  color: black;
}
.loginContents {
  width: 100%;
}
.loginContentsAuth {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.emailSection {
  display: flex;
  width: 100%;
  gap: 5px;
}
.loginContentsInput {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  padding: 0 10px;
  font-size: var(--size-font-paragraph3);
}
.loginContentsButton {
  display: flex;
  gap: 0.5rem;
  font-size: var(--size-font-paragraph2);
}
.findAuth {
  font-size: 16px;
}
.hr {
  border: none;
  border-top: 1px solid var(--color-lightGray);
  text-align: center;
  overflow: visible;
  margin: 60px 0 40px;
}

.hr:after {
  content: "간편 로그인";
  position: relative;
  top: -10px;
  background-color: white;
  padding: 0 10px;
}
.easyLogin {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconSection {
  display: flex;
  gap: 1rem;
}
.foundId {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.idSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.id {
  font-size: var(--size-font-heading1);
  font-weight: 700;
  color: var(--color-primary);
  margin: 10px 0 0;
}
.buttonSection {
  margin-top: 100px;
  width: 100%;
  gap: 10px;
  display: flex;
}
