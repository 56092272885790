.customerSection {
  position: relative;
  width: 100%;
  padding: 150px 0;
  background-color: white;
}
.homeCustomerBox {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: var(--size-max-width);
}
.section2title {
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}
.section2title > h1 {
  font-size: var(--size-font-heading1);
  color: var(--color-primary);
  margin: 0;
}
.ci {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
}
.ciBox {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5rem;
}
.awardsBox {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  gap: 3rem;
}
.awardsBox_image {
  border-radius: 20px;
  overflow: hidden;
  flex: 1 1 200px;
  max-width: 400px;
}
.images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.awardsBox_text {
  flex: 2 2 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  composes: paragraph1 from global;
  line-height: 1.3;
  white-space: pre-wrap;
}
.awardsBox_text > h3 {
  margin: -10px 0 50px;
  font-size: var(--size-font-heading2);
}

@media (max-width: 767px) {
  .customerSection {
    padding: 50px 0;
  }
  .awardsBox {
    flex-direction: column;
    align-items: center;
    /* margin: 0 auto; */
    width: 75%;
    gap: 1rem;
  }
  .awardsBox_image {
    border-radius: 20px;
    overflow: hidden;
    width: 75%;
    flex: unset;
    display: flex;
  }
  .awardsBox_text {
    flex: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    composes: paragraph2 from global;
    line-height: 1.3;
    white-space: pre-wrap;
  }
  .awardsBox_text > h3 {
    margin: -10px 0 10px;
    font-size: var(--size-font-heading2);
  }
}
