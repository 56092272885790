.technicalAccount {
  max-width: var(--size-max-width);
  margin: 100px auto;
  padding: 0 2rem;
}

.technicalAccountContents {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  line-height: 1.6rem;
}
.titleSection {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-weight: 700;
}
.titleSection span {
  white-space: nowrap;
}
.title {
  font-size: var(--size-font-heading2);
  color: var(--color-primary);
}
.date {
  text-align: end;
}
.contents {
  margin-bottom: 50px;
  white-space: pre-wrap;
}
.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 30px auto;
  width: 100%;
}
.image {
  margin: 50px;
  text-align: center;
}
.image img {
  width: 100%;
}
.editButton {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}
.postButton {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  gap: 0.7rem;
}
@media (max-width: 767px) {
  .technicalAccount {
    margin: 50px auto;
    padding: 0 1rem;
  }
  .technicalAccountContents {
    padding: 30px;
  }
  .image {
    margin: 5px;
  }
  .image img {
    width: 100%;
  }
}
