.deleteAccount {
  box-sizing: border-box;
  max-width: var(--size-max-width);
  margin: 100px auto;
  padding: 1rem;
}
.deleteAccountSection {
  display: flex;
  flex-direction: column;
  color: var(--color-primary);
}
.heading1 {
  margin: 0;
  font-size: var(--size-font-heading1);
}
.radioButtonSection {
  display: flex;
  flex-direction: column;
  margin: 30px 50px;
  gap: 1rem;
}
.radioButtonSection__input {
  display: flex;
  gap: 5px;
}
.noticeSection {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
  margin: 50px 0;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  line-height: 1.6rem;
}
.checkboxSection {
  display: flex;
  align-items: center;
  margin-left: 50px;
  gap: 5px;
}
.buttonSection {
  margin-top: 100px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
}
.li {
  list-style-type: disc;
  margin-bottom: 5px;
}
.modalSection {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20px;
  background-color: var(--color-lightGray);
  width: 800px;
  display: flex;
  padding: 100px;
  flex-direction: column;
  justify-content: center;
  color: var(--color-primary);
}
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.title {
  font-size: var(--size-font-heading2);
  font-weight: 700;
  color: black;
}
.buttonSection1 {
  margin-top: 100px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 1023px) {
  .deleteAccount {
    margin: 100px 50px;
  }
}
