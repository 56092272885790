.buttonStyle {
  padding: 10px 30px;
  height: 45px;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  background-color: white;
  color: var(--color-text);
  font-size: var(--size-font-paragraph3);
  font-weight: 500;
  font-family: "Pretendard Variable", Pretendard;
  gap: 5px;
  cursor: pointer;
}
.buttonStyle:hover {
  background-color: var(--color-lightGray);
}
.buttonStyleFullPrimary {
  padding: 10px 30px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: var(--color-accent);
  font-size: var(--size-font-paragraph3);
  font-weight: 500;
  font-family: "Pretendard Variable", Pretendard;
  /* box-shadow: #00000026 1.95px 1.95px 2.6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.buttonStyleFullPrimary:hover {
  background-color: var(--color-darkGray);
}
.buttonStyle svg {
  font-size: 20px;
}

.buttonStylePrimary {
  padding: 10px 30px;
  height: 45px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: var(--color-accent);
  font-size: var(--size-font-paragraph3);
  font-weight: 500;
  font-family: "Pretendard Variable", Pretendard;
  /* box-shadow: #00000026 1.95px 1.95px 2.6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.buttonStylePrimary:hover {
  background-color: var(--color-darkGray);
}
.buttonStyle svg {
  font-size: 20px;
}
