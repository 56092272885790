footer {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footerSection {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 60px 0;
  font-weight: 500;

  font-size: var(--size-font-paragraph2);
  letter-spacing: -0.5px;
  color: var(--color-gray);
  width: 100%;
  max-width: var(--size-max-width);
  padding: 50px 0 100px;
}
.footer::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-lightGray);
  margin-bottom: 3rem;
}
.left {
  display: flex;
  justify-content: space-between;
  margin-right: 100px;
}
.right > div {
  margin-bottom: 20px;
  line-height: 2;
}
.link {
  text-decoration: none;
  color: var(--color-text);
  margin: 0;
}
.ul {
  margin: 0;
}
.ul > p {
  margin-top: 0;
  margin-bottom: 20px;
}
li {
  margin-bottom: 10px;
}
.snsLink {
  font-size: 20px;
  letter-spacing: 10px;
  text-decoration: none;
  color: var(--color-text);
}

@media (max-width: 767px) {
  .footerSection {
    flex-direction: column;
    gap: 3rem;
  }
  .left {
    display: none;
  }
  /* .right::before {
    content: "";
    display: block;
    width: 80vw;
    height: 1px;
    background-color: var(--color-lightGray);
    margin-bottom: 3rem;
  } */
  .right {
    padding: 0 0 0 40px;
  }
}
