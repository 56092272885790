.deletionCompleteSection {
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: var(--color-primary);
}
.buttonSection {
  margin-top: 50px;
}
