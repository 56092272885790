.homeSearchSection {
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}
.divideSection {
  width: 100%;
  max-width: var(--size-max-width);
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.searchBar {
  width: 100%;
}
.searchSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.heading1 {
  composes: heading1 from global;
  color: var(--color-primary);
  font-weight: 700;
}
.form {
  /* display: flex;
  justify-content: left; */

  display: flex; /* 플렉스 컨테이너 설정 */
  align-items: center; /* 수직 중앙 정렬 */
  width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  max-width: 500px; /* 최대 너비를 설정하여 대형 화면에서 제한 */
  margin-left: auto;
  margin-right: 0;
}
.input {
  /* position: relative;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 40px;
  min-width: 350px;
  max-width: 600px;
  width: 100%;
  composes: paragraph2 from global; */

  flex: 1; /* input 요소가 가용 가능한 모든 공간을 차지하도록 설정 */
  height: 50px; /* input의 높이 설정 */
  padding-right: 40px; /* 아이콘 버튼과 겹치지 않도록 오른쪽 패딩 추가 */
  box-sizing: border-box; /* padding과 border를 포함한 크기 계산 */
  border: 1px solid lightgray;
  border-radius: 4px 0 0 4px; /* 둥근 모서리 설정 */
}
.searchButton {
  height: 50px; /* 버튼 높이를 input과 동일하게 설정 */
  padding: 0 10px; /* 버튼의 좌우 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-left: none; /* input과 버튼 사이의 테두리 제거 */
  background: #fff; /* 배경색 설정 */
  cursor: pointer;
  border-radius: 0 4px 4px 0; /* 둥근 모서리 설정 */
  display: flex;
  align-items: center; /* 버튼 내 아이콘 수직 중앙 정렬 */
  justify-content: center; /* 버튼 내 아이콘 수평 중앙 정렬 */
}

.searchButton > svg {
  width: 20px; /* 아이콘 크기 */
  height: 20px; /* 아이콘 크기 */
  color: var(--color-gray);
}
.faqSection {
  flex: 2 2 700px;
}
.faqContents {
  line-height: 1.5rem;
}
.faqTitleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faqTitle {
  composes: paragraph1 from global;
  font-weight: 700;
  color: var(--color-primary);
}
.arrowIcon {
  width: 50px;
  height: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hr {
  margin: 30px auto;
  width: 100%;
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .homeSearchSection {
    padding: 0.5rem 50px;
  }
  .divideSection {
    flex-direction: column;
    gap: 0;
  }

  .searchSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: unset;
    margin-bottom: 20px;
  }
  .heading1 {
    margin-top: -10px;
    margin-bottom: unset;
  }
  .faqSection {
    width: 100%;
    flex: unset;
  }
}
@media (max-width: 767px) {
  .homeSearchSection {
    margin: 50px auto;
    padding: 0 20px;
  }
  .divideSection {
    flex-direction: column;
    padding: 50px 0;
    gap: 0;
  }
  .heading1 {
    margin-bottom: 20px;
  }
  .searchSection {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: unset;
    margin-bottom: 1rem;
  }
  .form {
    max-width: 700px;
  }
  .faqSection {
    width: 100%;
    flex: unset;
  }
}
