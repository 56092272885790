.completePaymentSection {
  box-sizing: border-box;
  width: 100%;
  max-width: var(--size-max-width);
  color: var(--color-primary);
  text-align: center;
  margin: 150px auto 0;

  font-size: var(--size-font-paragraph1);
}
.completePaymentSection svg {
  font-size: 200px;
}
.buttonSection {
  margin: 50px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.depositInfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-lightGray);
  border-radius: 10px;
  width: 600px;
  margin: 30px auto;
  padding: 50px;
  background-color: white;
  font-size: var(--size-font-paragraph2);
}
.table {
  width: 450px;
  box-sizing: border-box;
}
.table th {
  background-color: var(--color-lightGray);
  box-sizing: border-box;
}
.table td,
.table tr,
.table tr:hover {
  background-color: unset;
  text-align: left;
  border-collapse: collapse;
  color: var(--color-primary);
  font-weight: 400;
  box-sizing: border-box;
}
