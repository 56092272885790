.writeSection {
  max-width: var(--size-max-width);
  margin: 100px auto;
}
.writeSection {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  line-height: 1.6rem;
}
.titleSection {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-weight: 700;
}
.title {
  font-size: var(--size-font-heading2);
  color: var(--color-primary);
}

.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 30px auto;
  width: 100%;
}
.contentsSection {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  gap: 3rem;
}
.contentsSection__title,
.contentsSection__type,
.contentsSection__image,
.contentsSection__contents {
  display: flex;
}

.contentsSection__title label,
.contentsSection__type__title,
.contentsSection__image label,
.contentsSection__contents label {
  width: 10%;
  min-width: 90px;
}
.contentsSection__title input {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 90%;
  height: 40px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph2);
}
.contentsSection__image__contents {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  padding: 20px;
  width: 90%;
  height: 100px;
}
.contentsSection__contents textarea {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 90%;
  height: 600px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph2);
}
.contentsSection__type__item {
  width: 90%;
  display: flex;
  gap: 2rem;
}
.postButton {
  display: flex;
  justify-content: center;
  margin: 50px auto;
  gap: 1rem;
}

@media (max-width: 768px) {
  .contentsSection__type__item {
    flex-direction: column;
    gap: 1rem;
  }
}
