.modalSection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20px;
  background-color: var(--color-lightGray);
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalSection svg {
  color: black;
  font-size: 100px;
}
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.title {
  font-size: var(--size-font-heading2);
  font-weight: 700;
  color: black;
}
