@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.signUpSection {
  margin: 150px auto 250px;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.signUp {
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  padding: 100px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  animation: fadeIn 0.5s ease-out forwards;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signUpTitle {
  font-size: var(--size-font-heading2);
  font-weight: 700;
  color: black;
}
.signUpContents {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contentsSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
  gap: 5px;
}
.contentsSection__validation {
  width: 80%;
}
.contentSection label {
  width: 20%;
  min-width: 90px;
}
.contentsSection input {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 80%;
  height: 40px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph3);
}
.contentsSection__validation input {
  width: 100%;
}
.contentsSection__validation__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.contentsSection__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  gap: 10px;
}
.validation p {
  font-size: var(--size-font-paragraph3);
  margin: 0 10px;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.validation svg {
  font-size: 20px;
  color: red;
  padding-right: 5px;
}
.termsofuse {
  width: 100%;
  box-sizing: border-box;
}
.checkboxArrowSection {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.checkboxArrowSection__checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}
.checkboxSection__checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}
.termsofuse {
  margin: 50px 0;
}
.termsofuse textarea {
  box-sizing: border-box;
  padding: 15px;
  width: 95%;
  height: 100px;
  border: 1px solid var(--color-lightGray);
}

.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 20px auto;
  width: 100%;
}
.buttonSection {
  margin: 50px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}
