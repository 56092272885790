form {
  display: flex;
  position: relative;
  justify-content: end;
}
.input {
  flex: 1; /* input 요소가 가용 가능한 모든 공간을 차지하도록 설정 */
  height: 50px; /* input의 높이 설정 */
  padding-right: 40px; /* 아이콘 버튼과 겹치지 않도록 오른쪽 패딩 추가 */
  box-sizing: border-box; /* padding과 border를 포함한 크기 계산 */
  border: 1px solid lightgray;
  border-radius: 4px 0 0 4px; /* 둥근 모서리 설정 */
}
.searchButton {
  height: 50px; /* 버튼 높이를 input과 동일하게 설정 */
  padding: 0 10px; /* 버튼의 좌우 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-left: none; /* input과 버튼 사이의 테두리 제거 */
  background: #fff; /* 배경색 설정 */
  cursor: pointer;
  border-radius: 0 4px 4px 0; /* 둥근 모서리 설정 */
  display: flex;
  align-items: center; /* 버튼 내 아이콘 수직 중앙 정렬 */
  justify-content: center; /* 버튼 내 아이콘 수평 중앙 정렬 */
}

.searchButton > svg {
  width: 20px; /* 아이콘 크기 */
  height: 20px; /* 아이콘 크기 */
}
@media (max-width: 767px) {
  .searchButton {
    margin-top: 20px;
  }
  .input {
    width: 100%;
    margin-top: 20px;
  }
}
