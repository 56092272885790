.mypageSection {
  display: flex;
  justify-content: center;
  height: 1000px;
  margin: 100px 0;
}

.menuSection {
  width: 250px;
  color: var(--color-primary);
  font-size: var(--size-font-paragraph1);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menuSection__title li {
  cursor: pointer;
  margin-bottom: 20px;
}
.heading1 {
  font-size: var(--size-font-heading1);
  margin-bottom: 40px;
}
.stateBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  background-color: var(--color-lightGray);
  padding: 25px;
  margin: 10px;
  font-size: var(--size-font-paragraph2);
}
.stateBox h3 {
  color: var(--color-text);
  font-size: var(--size-font-paragraph2);
}

.hr {
  background: var(--color-lightGray);
  height: 1px;
  border: 0;
  margin: 30px auto;
  width: 100%;
}
.table1 td,
.table1 th,
.table1 tr,
.table1 tr:hover {
  background-color: unset;
  text-align: unset;
  border: unset;
  box-sizing: border-box;
  white-space: normal;
}
.table1 td input {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph3);
}
.table1 tr:hover,
.table3 tr:hover {
  background-color: none;
}

.table3 td,
.table3 th,
.table3 tr,
.table3 tr:hover {
  background-color: unset;
  text-align: unset;
  border: unset;
  box-sizing: border-box;
}
.table3 td {
  padding: 0;
}
.table3 td input {
  box-sizing: border-box;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 20px;
  font-family: pretendard;
  font-size: var(--size-font-paragraph3);
}
.table2 {
  width: 100%;
}
.table2 th {
  box-sizing: border-box;
  padding: 20px;
}
.table2 td,
.table2 tr {
  white-space: normal;
  box-sizing: border-box;
  word-wrap: break-word;

  /* text-align: unset; */
}
.table2__align {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.table2__align label {
  margin: 0 20px 0 0;
}
.table2 tr:hover {
  background-color: #fafafa;
}
.pageSection {
  box-sizing: border-box;
  width: 100%;
  max-width: 950px;
  padding: 70px 20px;
}
.pageSection__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading2 {
  font-size: var(--size-font-heading2);
  color: var(--color-primary);
  margin: 0;
}
.withDrawal {
  text-align: end;
}
.buttonSection {
  margin: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.passwordContentsAuth {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.passwordContents__currentPassord {
  display: flex;
  align-items: baseline;
  width: 100%;
}
.passwordContents__currentPassord label,
.passwordContents__currentPassord__id {
  width: 20%;
}
.passwordContentsValidation {
  width: 80%;
}
.passwordContentsInput {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  padding: 0 10px;
  font-size: var(--size-font-paragraph3);
}
.validation p {
  font-size: var(--size-font-paragraph3);
  margin: 10px;
  display: flex;
  align-items: center;
}
.validationInfo p {
  font-size: var(--size-font-paragraph3);
  display: flex;
  margin: 5px;
}
.validation svg {
  font-size: 30px;
  color: var(--color-accent);
}
@media screen and (max-width: 1023px) {
  .mypageSection {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: 50px;
  }
  .menuSection {
    width: 100%;
  }
  .menuSection__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--color-lightGray);
    z-index: 1;
  }
  .menuSection__title__list {
    display: flex;
    gap: 1rem;
  }
  .menuSection__title__list li {
    margin: 0;
  }
  .stateBox {
    margin-top: 0;
    margin-left: auto;
    flex-direction: row;
    gap: 3rem;
    padding: 0 30px;
    border-radius: 0 0 10px 10px;
  }
  .stateBox div {
    display: flex;
    gap: 0.7rem;
  }
  .pageSection {
    padding: 70px 50px;
  }
}
